import Vue from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  LayoutPlugin,
  ModalPlugin,
  ToastPlugin,
  AlertPlugin,
  NavbarPlugin,
  TooltipPlugin,
  SkeletonPlugin,
  CardPlugin,
  BadgePlugin,
  TablePlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  ListGroupPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  TabsPlugin,
  InputGroupPlugin,
  SpinnerPlugin,
  PopoverPlugin,
  OverlayPlugin,
  ProgressPlugin,
  AvatarPlugin,
} from "bootstrap-vue";

const bootstrapPlugins = [
  LayoutPlugin,
  ModalPlugin,
  ToastPlugin,
  AlertPlugin,
  NavbarPlugin,
  TooltipPlugin,
  SkeletonPlugin,
  CardPlugin,
  BadgePlugin,
  TablePlugin,
  ButtonGroupPlugin,
  ButtonPlugin,
  ListGroupPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  TabsPlugin,
  InputGroupPlugin,
  SpinnerPlugin,
  PopoverPlugin,
  OverlayPlugin,
  ProgressPlugin,
  AvatarPlugin,
];
bootstrapPlugins.forEach((plugin) => {
  Vue.use(plugin);
});

import {
  BIconCheckAll,
  BIconSlash,
  BIconArrowRepeat,
  BIconPen,
  BIconPlus,
  BIconCheck,
  BIconThreeDotsVertical,
  BIconFilePdf,
  BIconFilm,
  BIconDownload,
  BIconChevronLeft,
  BIconChevronRight,
  BIconTrash,
  BIconX,
  BIconPeopleFill,
  BIconArrowRight,
  BIconArrowLeft,
  BIconArrowDown,
  BIconArrowUp,
  BIconStar,
  BIconStarFill,
  BIconCaretDownFill,
  BIconCaretUpFill,
  BIconShare,
  BIconCheckCircle,
  BIconXCircle,
  BIconQuestionCircle,
  BIconQuestion,
  BIconPersonCircle,
  BIconDoorOpen,
  BIconPlay,
  BIconPause,
  BIconSkipStartFill,
  BIconKey,
  BIconChevronDoubleLeft,
  BIconChevronDoubleRight,
  BIconHash,
  BIconBoxArrowRight,
  BIconBoxArrowInRight,
  BIconLayoutThreeColumns,
  BIconFilePdfFill,
  BIconShieldFillCheck,
  BIconCalendar2RangeFill,
  BIconTrophyFill,
  BIconArchiveFill,
  BIconPersonPlusFill,
  BIconChatFill,
  BIconFileEarmarkArrowUpFill,
  BIconInfoCircle,
  BIconInfo,
  BIconPlusSquare,
  BIconTable,
  BIconListUl,
  BIconInstagram,
  BIconFacebook,
  BIconGithub,
  BIconExclamationTriangleFill,
  BIconHouseFill,
  BIconArrowCounterclockwise,
  BIconPerson,
} from "bootstrap-vue";

const icons = [
  BIconCheckAll,
  BIconSlash,
  BIconArrowRepeat,
  BIconPen,
  BIconPlus,
  BIconCheck,
  BIconThreeDotsVertical,
  BIconFilePdf,
  BIconFilm,
  BIconDownload,
  BIconChevronLeft,
  BIconChevronRight,
  BIconTrash,
  BIconX,
  BIconPeopleFill,
  BIconArrowRight,
  BIconArrowLeft,
  BIconArrowDown,
  BIconArrowUp,
  BIconStar,
  BIconStarFill,
  BIconCaretDownFill,
  BIconCaretUpFill,
  BIconShare,
  BIconCheckCircle,
  BIconXCircle,
  BIconQuestionCircle,
  BIconQuestion,
  BIconPersonCircle,
  BIconDoorOpen,
  BIconPlay,
  BIconPause,
  BIconSkipStartFill,
  BIconKey,
  BIconChevronDoubleLeft,
  BIconChevronDoubleRight,
  BIconHash,
  BIconBoxArrowRight,
  BIconBoxArrowInRight,
  BIconLayoutThreeColumns,
  BIconFilePdfFill,
  BIconShieldFillCheck,
  BIconCalendar2RangeFill,
  BIconTrophyFill,
  BIconArchiveFill,
  BIconPersonPlusFill,
  BIconChatFill,
  BIconFileEarmarkArrowUpFill,
  BIconInfoCircle,
  BIconInfo,
  BIconPlusSquare,
  BIconTable,
  BIconListUl,
  BIconInstagram,
  BIconFacebook,
  BIconGithub,
  BIconExclamationTriangleFill,
  BIconHouseFill,
  BIconArrowCounterclockwise,
  BIconPerson,
];
icons.forEach((icon) => {
  const componentString = icon.options.name
    .replace(/([A-Z])/g, " $1")
    .split(" ")
    .slice(1)
    .join("-")
    .toLowerCase();
  Vue.component(componentString, icon);
});
